import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import logoLight from "../../assets/teaboy/logo-light.png";

import "react-toastify/dist/ReactToastify.css";

const ValidationEmailSent = () => {
  const navigate = useNavigate();
  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={logoLight} alt="logo" height="20" />
                </Link>
                <p className="mt-3 fs-15 fw-medium">
                  Elevating next-level hospitality.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="mt-4">
                <CardBody className="p-4 text-center">
                  <h5 className="text-primary">Thank you</h5>
                  <p className="text-muted">
                    We have sent you a email to validate your email address.
                  </p>

                  <div className="mt-4">
                    <Button color="primary" onClick={() => navigate("/login")}>
                      Go to Login
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <ToastContainer autoClose={2000} limit={1} /> */}
    </ParticlesAuth>
  );
};

export default ValidationEmailSent;
