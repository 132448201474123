import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Input,
  FormGroup,
} from "reactstrap"; // Make sure you have CustomInput from reactstrap for switch
import { useSpaceStore } from "../../../../stores/Assets/space";
import { SketchPicker } from "react-color";
import Select from "react-select";
import { Link } from "react-router-dom";

const validTypes = ["MAJLISS", "OFFICE", "RESTAURANT", "BEACH"];
const languages = [
  {
    label: "ENGLISH",
    value: "EN",
  },
  {
    label: "ARABIC",
    value: "AR",
  },
];

const AddSpace = ({
  kitchenList,
  menuList,
  siteList,
  showAddSpaceModal,
  toggleAddSpaceModal,
}) => {
  const [selectedSiteId, setSelectedSiteId] = useState(
    siteList[0]?.site_id || null
  );
  const [selectedKichenId, setSelectedKichenId] = useState(
    kitchenList[0]?.kitchen_id || null
  );
  const [selectedMenuIds, setSelectedMenuIds] = useState(
    menuList.length > 0 ? [menuList[0]?.menu_id] : []
  );
  const { createSpace, isLoading } = useSpaceStore((state) => state);

  const [currKitchens, setCurrentKitchens] = useState([]);
  const [currMenus, setCurrMenus] = useState([]);
  const [theme1, setTheme1] = useState("");
  const [theme2, setTheme2] = useState("");
  const [useCustomTheme, setUseCustomTheme] = useState(false); // New state to control the switch

  useEffect(() => {
    if (selectedSiteId) {
      const filteredKitchens = kitchenList.filter(
        (kitchen) => kitchen?.site?.site_id === parseInt(selectedSiteId)
      );
      const filteredMenus = menuList.filter(
        (menu) =>
          menu?.sites.length > 0 &&
          menu?.sites[0].site_id === parseInt(selectedSiteId)
      );
      setCurrMenus(filteredMenus);
      setCurrentKitchens(filteredKitchens);
    }
  }, [selectedSiteId]);

  useEffect(() => {
    if (selectedSiteId && currMenus.length > 0) {
      setSelectedMenuIds([currMenus[0]?.menu_id]);
    }

    if (selectedSiteId && currKitchens.length > 0) {
      setSelectedKichenId(currKitchens[0]?.kitchen_id || null);
    }
  }, [currMenus, currKitchens]);

  const formik = useFormik({
    initialValues: {
      name: "",
      name_ar: "",
      default_lang: languages[0].value,
      type: validTypes[0],
      theme: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("Required")),
      name_ar: Yup.string().optional(),
      default_lang: Yup.string().required(t("Required")),
      theme: Yup.string()
        .matches(/^#[0-9A-Fa-f]{6}$/, t("Invalid hex color"))
        .optional(),
      type: Yup.string()
        .oneOf(validTypes, t("Invalid type"))
        .required(t("Required")),
    }),
    onSubmit: async (values) => {
      let theme = "";
      if (!useCustomTheme) {
        theme = ""; // No theme if custom theme is not enabled
      } else if (theme1 && !theme2) {
        theme = theme1;
      } else if (!theme1 && theme2) {
        theme = theme2;
      } else {
        theme = `${theme1},${theme2}`;
      }

      const spaceData = {
        name: values.name,
        name_ar: values.name_ar,
        default_lang: values.default_lang,
        type: values.type,
        theme: theme,
      };
      console.log(spaceData, selectedMenuIds, selectedSiteId, selectedKichenId);

      const result = await createSpace(
        selectedSiteId,
        spaceData,
        selectedKichenId,
        selectedMenuIds
      );
      if (result) {
        formik.resetForm();
        setSelectedSiteId(siteList[0]?.site_id || null); // Reset to first site after submission
        toggleAddSpaceModal();
      }
    },
  });

  return (
    <Modal isOpen={showAddSpaceModal} toggle={toggleAddSpaceModal}>
      <ModalHeader toggle={toggleAddSpaceModal}>
        {t("Add New Space")}
      </ModalHeader>
      <ModalBody>
        {siteList.length === 0 ? (
          <div className="text-center">
            <p className="text-danger">{t("You should first add a site.")}</p>
            <Link to="/sites" className="btn btn-primary">
              {t("Go to Sites")}
            </Link>
          </div>
        ) : (
          <form
            onSubmit={formik.handleSubmit}
            className="d-flex flex-column flex-wrap"
          >
            <div className="flex-fill mb-2">
              <Label for="site">{t("Select Site")}</Label>
              <select
                id="site"
                onChange={(e) => setSelectedSiteId(e.target.value)}
                value={selectedSiteId}
                className="form-control"
              >
                <option value="">Select</option>
                {siteList.map((site) => (
                  <option key={site.site_id} value={site.site_id}>
                    {site.site_id} - {site.name}
                  </option>
                ))}
              </select>
            </div>
            {selectedSiteId && (
              <>
                <div className="flex-fill mb-2">
                  <Label for="Kitchen">{t("Select Kitchen")}</Label>
                  <select
                    id="Kitchen"
                    onChange={(e) => setSelectedKichenId(e.target.value)}
                    value={selectedKichenId}
                    className="form-control"
                  >
                    {currKitchens.map((kitchen) => (
                      <option
                        key={kitchen.kitchen_id}
                        value={kitchen.kitchen_id}
                      >
                        {kitchen.kitchen_id} - {kitchen.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex-fill mb-2">
                  <Label for="Menu">{t("Select Menu")}</Label>
                  <Select
                    id="Menu"
                    isMulti
                    options={currMenus.map((menu) => ({
                      value: menu.menu_id,
                      label: `${menu.menu_id} - ${menu.name}`,
                    }))}
                    value={currMenus
                      .filter((menu) => selectedMenuIds.includes(menu.menu_id))
                      .map((menu) => ({
                        value: menu.menu_id,
                        label: `${menu.menu_id} - ${menu.name}`,
                      }))}
                    onChange={(selectedOptions) =>
                      setSelectedMenuIds(
                        selectedOptions.map((option) => option.value)
                      )
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </>
            )}

            <div className="flex-fill mb-2">
              <Label for="name">{t("Name")}</Label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                className="form-control"
              />
              {formik.errors.name && (
                <div className="text-danger">{formik.errors.name}</div>
              )}
            </div>
            <div className="flex-fill mb-2">
              <Label for="name_ar">{t("Name (AR)")}</Label>
              <input
                type="text"
                id="name_ar"
                name="name_ar"
                onChange={formik.handleChange}
                value={formik.values.name_ar}
                className="form-control"
              />
              {formik.errors.name_ar && (
                <div className="text-danger">{formik.errors.name_ar}</div>
              )}
            </div>
            <div className="flex-fill mb-2">
              <Label for="default_lang">{t("Default Language")}</Label>
              <select
                id="default_lang"
                name="default_lang"
                onChange={formik.handleChange}
                value={formik.values.default_lang}
                className="form-control"
              >
                {languages.map((type) => (
                  <option
                    key={type.value}
                    value={type.value}
                    label={type.label}
                  />
                ))}
              </select>
              {formik.errors.default_lang && (
                <div className="text-danger">{formik.errors.default_lang}</div>
              )}
            </div>

            <div className="flex-fill mb-2">
              <Label for="type">{t("Type")}</Label>
              <select
                id="type"
                name="type"
                onChange={formik.handleChange}
                value={formik.values.type}
                className="form-control"
              >
                {validTypes.map((type) => (
                  <option key={type} value={type} label={type} />
                ))}
              </select>
              {formik.errors.type && (
                <div className="text-danger">{formik.errors.type}</div>
              )}
            </div>

            {/* Switch to enable or disable custom theme */}
            <div className="flex-fill mb-2">
              <FormGroup switch>
                <Input
                  type="switch"
                  id="useCustomTheme"
                  name="useCustomTheme"
                  role
                  checked={useCustomTheme}
                  onChange={() => setUseCustomTheme(!useCustomTheme)}
                  label={t("Enable Custom Theme")}
                />{" "}
                <Label for="useCustomTheme">
                  {t("Use Custom Theme Colors")}
                </Label>
              </FormGroup>

              {/**/}
            </div>

            {useCustomTheme && (
              <div className="d-flex flex-row justify-content-between gap-2">
                <div className="flex-fill mb-2">
                  <Label for="theme">{t("Theme Color 1 ")}</Label>
                  <SketchPicker
                    color={theme1}
                    onChange={(color) => setTheme1(color.hex)}
                  />
                </div>
                <div className="flex-fill mb-2">
                  <Label for="theme">{t("Theme Color 2 ")}</Label>
                  <SketchPicker
                    color={theme2}
                    onChange={(color) => setTheme2(color.hex)}
                  />
                </div>
              </div>
            )}

            <div className="d-flex flex-row align-items-center justify-content-center gap-2 mt-2">
              <Button type="submit" color="success" disabled={isLoading}>
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <span>{t("Add Space")}</span>
                )}
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  toggleAddSpaceModal();
                  formik.resetForm();
                  setSelectedSiteId(siteList[0]?.site_id || null);
                }}
                disabled={isLoading}
              >
                {t("Cancel")}
              </Button>
            </div>
          </form>
        )}
      </ModalBody>
      <ModalFooter />
    </Modal>
  );
};

export default AddSpace;
