import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Input,
  FormGroup,
} from "reactstrap";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useMenuItemsStore } from "../../../stores/Assets/menuItems";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import { FormControlLabel, Switch } from "@mui/material";

// Custom Leaflet icon
const customIcon = new L.Icon({
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  shadowSize: [41, 41],
});

const EditItem = ({
  menuId,
  rowData,
  showEditItemModal,
  toggleEditItemModal,
  image_url,
}) => {
  const [existingImages, setExistingImages] = useState([]); // Track existing images
  const [imagesToDelete, setImagesToDelete] = useState([]); // Track images to delete
  const [newImages, setNewImages] = useState([]); // Track new images to add
  const [activeIndex, setActiveIndex] = useState(0);

  const { updateItem, isLoading } = useMenuItemsStore((state) => state);

  // Handle file selection for new images
  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    const imageFiles = files.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      isNew: true,
    }));

    setNewImages((prev) => [...prev, ...imageFiles]);
  };

  // Mark an existing image for deletion
  const handleDeleteExistingImage = (index) => {
    console.log(index, existingImages);

    const imageToDelete = existingImages[index];
    console.log(imageToDelete);

    setImagesToDelete([...imagesToDelete, imageToDelete]);
    setExistingImages(existingImages.filter((_, i) => i !== index));

    // Adjust active index if needed
    if (activeIndex >= existingImages.length - 1) {
      setActiveIndex(Math.max(0, existingImages.length - 2));
    }
  };

  // Remove a new image that hasn't been uploaded yet
  const handleRemoveNewImage = (index) => {
    // Release the object URL to prevent memory leaks
    URL.revokeObjectURL(newImages[index].preview);

    setNewImages(newImages.filter((_, i) => i !== index));
    console.log(newImages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (rowData) {
      // Initialize existing images from rowData
      setExistingImages(rowData?.images || []);
      setImagesToDelete([]); // Reset images to delete
      setNewImages([]); // Reset new images

      // Set initial form values
      formik.setValues({
        title: rowData?.title || "",
        title_ar: rowData?.title_ar || "",
        description: rowData?.description || "",
        price: rowData?.price || "",
        available: rowData?.available,
      });
    }
  }, [rowData]);

  // Cleanup object URLs on component unmount
  useEffect(() => {
    return () => {
      newImages.forEach((image) => {
        if (image.preview) {
          URL.revokeObjectURL(image.preview);
        }
      });
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      title: rowData?.title || "",
      title_ar: rowData?.title_ar || "",
      description: rowData?.description || "",
      price: rowData?.price || "",
      available: rowData?.available,
    },
    validationSchema: Yup.object({
      title: Yup.string().optional(),
      title_ar: Yup.string().optional(),
      description: Yup.string().optional(),
      price: Yup.number().optional(),
      available: Yup.boolean().optional(),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();

      // Append text fields
      formData.append("title", values.title);
      formData.append("title_ar", values.title_ar);
      formData.append("description", values.description);
      formData.append("price", values.price || 0);
      formData.append("available", values.available);

      // Append images to delete
      formData.append("deletedImages", JSON.stringify(imagesToDelete));
      // Append new images
      newImages.forEach(({ file }) => {
        formData.append("newImages", file);
      });
      console.log(formData);
      //print the formData
      formData.forEach((value, key) => {
        console.log(key, value);
      });
      try {
        await updateItem(menuId, rowData?.item_id, formData);
        // Reset form and state
        formik.resetForm();
        setNewImages([]);
        setExistingImages([]);
        setImagesToDelete([]);
        toggleEditItemModal();
      } catch (error) {
        console.error("Error updating item:", error);
      }
    },
  });

  // Carousel navigation
  const next = () => {
    const nextIndex =
      activeIndex === existingImages.length + newImages.length - 1
        ? 0
        : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex =
      activeIndex === 0
        ? existingImages.length + newImages.length - 1
        : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  // Combine existing and new images for display
  const allImages = [
    ...existingImages.map((image) => ({
      url: `${image_url}${image}`,
      isExisting: true,
      name: image,
    })),
    ...newImages.map((image) => ({
      url: image.preview,
      isNew: true,
      file: image.file,
      name: image.file.name,
    })),
  ];

  return (
    <Modal isOpen={showEditItemModal} toggle={toggleEditItemModal} size="lg">
      <ModalHeader toggle={toggleEditItemModal}>
        {t("Modify Item: ")} {rowData?.title}
      </ModalHeader>
      <ModalBody>
        <div className="mb-4">
          <h5>{t("Item Images")}</h5>

          {allImages.length > 0 ? (
            <div
              className="position-relative bg-warning rounded"
              style={{
                boxShadow: "inset 0 0 20px rgba(0,0,0,0.1)", // Soft inset shadow
                borderRadius: "8px", // Smooth rounded edges
                padding: "10px", // Adds spacing around the carousel
              }}
            >
              {" "}
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={allImages}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {allImages.map((image, index) => (
                  <CarouselItem key={index}>
                    <div
                      className="position-relative "
                      style={{ height: "300px" }}
                    >
                      <img
                        src={image.url}
                        alt={`Image ${index}`}
                        style={{
                          width: "100%",
                          height: "300px",
                          objectFit: "contain",
                        }}
                      />
                      <Button
                        color="danger"
                        size="sm"
                        className="position-absolute z-5"
                        style={{ top: "10px", right: "10px" }}
                        onClick={() =>
                          image.isExisting
                            ? handleDeleteExistingImage(
                                existingImages.findIndex(
                                  (img) => img === image.name
                                )
                              )
                            : handleRemoveNewImage(
                                newImages.findIndex(
                                  (img) => img.file === image.file
                                )
                              )
                        }
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Button>
                      <div
                        className="position-absolute"
                        style={{
                          bottom: "10px",
                          left: "10px",
                          backgroundColor: "rgba(0,0,0,0.5)",
                          color: "white",
                          padding: "5px",
                        }}
                      >
                        {image.isExisting ? "Existing Image" : "New Image"}
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </Carousel>
            </div>
          ) : (
            <div className="text-center p-4 bg-light">
              <p>{t("No images available")}</p>
            </div>
          )}

          <div className="mt-3">
            <Label for="images">{t("Upload New Images")}</Label>
            <Input
              type="file"
              id="images"
              name="images"
              accept="image/*"
              multiple
              onChange={handleImageChange}
            />
          </div>

          {imagesToDelete.length > 0 && (
            <div className="mt-3">
              <h6>{t("Images marked for deletion:")}</h6>
              <div className="d-flex flex-wrap">
                {imagesToDelete.map((image, index) => (
                  <div key={index} className="position-relative m-1">
                    <img
                      src={`${image_url}${image}`}
                      alt={`Delete ${index}`}
                      style={{
                        width: "60px",
                        height: "60px",
                        objectFit: "cover",
                      }}
                      className="border"
                    />
                    <Button
                      color="success"
                      size="sm"
                      className="position-absolute"
                      style={{
                        top: "0",
                        right: "0",
                        padding: "2px",
                        fontSize: "10px",
                      }}
                      onClick={() => {
                        setExistingImages([...existingImages, image]);
                        setImagesToDelete(
                          imagesToDelete.filter((_, i) => i !== index)
                        );
                      }}
                    >
                      ↩
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <form
          onSubmit={formik.handleSubmit}
          className="d-flex flex-column gap-1"
        >
          <Label for="title">{t("Title")}</Label>
          <Input
            type="text"
            id="title"
            name="title"
            {...formik.getFieldProps("title")}
            invalid={formik.touched.title && Boolean(formik.errors.title)}
            placeholder={t("Enter item title")}
          />
          <Label for="title_ar">{t("Title (AR)")}</Label>
          <Input
            type="text"
            id="title_ar"
            name="title_ar"
            {...formik.getFieldProps("title_ar")}
            invalid={formik.touched.title_ar && Boolean(formik.errors.title_ar)}
            placeholder={t("Enter item title_ar")}
          />
          <Label for="description">{t("Description")}</Label>
          <Input
            type="textarea"
            id="description"
            name="description"
            {...formik.getFieldProps("description")}
            placeholder={t("Enter item description")}
          />
          <Label for="price">{t("Price")}</Label>
          <Input
            type="number"
            id="price"
            name="price"
            {...formik.getFieldProps("price")}
            placeholder={t("Enter item price")}
          />
          <FormControlLabel
            control={
              <Switch
                checked={formik.values.available}
                onChange={(event) =>
                  formik.setFieldValue("available", event.target.checked)
                }
              />
            }
            label="Available"
          />
          <div className="d-flex flex-row align-items-center justify-content-center gap-2 mt-2">
            <Button type="submit" color="success" disabled={isLoading}>
              {isLoading ? <Spinner size={"sm"} /> : <span>{t("Save")}</span>}
            </Button>
            <Button
              color="danger"
              onClick={() => {
                toggleEditItemModal();
                formik.resetForm();
                setNewImages([]);
                setExistingImages(rowData?.images || []);
                setImagesToDelete([]);
              }}
              disabled={isLoading}
            >
              {t("Cancel")}
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default EditItem;
